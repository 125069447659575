import React, { useState, useEffect } from "react";
import {
  getSettings,
  updateSettings,
  updateClubLogo,
} from "../../../services/settingsServices";
import Button from "../../../components/Button/Button";
import { toast } from "react-toastify";
import { FaSave, FaTimes, FaUpload } from "react-icons/fa";
import { useUser } from "../../Home/UserContext"; // Importer useUser
import { getRoleFromRoleId } from "../../../utils/roleHelper";


const SettingsForm = () => {
  const [settings, setSettings] = useState({
    nom_club: "",
    shortname_club: "",
    responsable_club: "",
    logo_club: "",
    adresse_club: "",
    commune_club: "",
    tel_club: "",
    mail_club: "",
    siteweb_club: "",
    siret_club: "",
    facebook_club: "",
    instagram_club: "",
    primary_color: "",
    autorisation_renouvellement: false,
    saison_active: "",
  });
  const [loading, setLoading] = useState(true);
  const [isUploading, setIsUploading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
   const { user } = useUser(); // Récupérer l'utilisateur
  const userRole = getRoleFromRoleId(user?.role_id);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const settingsData = await getSettings();
        if (settingsData.length > 0) {
          setSettings(settingsData[0]);
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des données:", error);
      }
      setLoading(false);
    };

    fetchSettings();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setSettings((prevSettings) => ({
      ...prevSettings,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateSettings(settings);
      toast.success("Paramètres mis à jour avec succès");
    } catch (error) {
      toast.error("Erreur lors de la mise à jour des paramètres");
    }
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const openUploadModal = () => {
    setIsUploadModalOpen(true);
  };

  const closeUploadModal = () => {
    setIsUploadModalOpen(false);
    setSelectedFile(null);
  };

  const handleUploadSubmit = async (e) => {
    e.preventDefault();
    if (!selectedFile) {
      toast.error("Veuillez sélectionner une image.");
      return;
    }

    setIsUploading(true);

    try {
      const token = localStorage.getItem("token");
      const imageUrl = await updateClubLogo(selectedFile, token);
      setSettings((prevSettings) => ({
        ...prevSettings,
        logo_club: imageUrl,
      }));
      toast.success("Logo du club mis à jour avec succès!");
      closeUploadModal();
    } catch (error) {
      console.error("Erreur lors de l'upload de l'image :", error);
      toast.error("Erreur lors de l'upload de l'image.");
    } finally {
      setIsUploading(false);
    }
  };

  const getSeasonOptions = () => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    let startYear;
    if (currentMonth >= 7) {
      startYear = currentYear;
    } else {
      startYear = currentYear - 1;
    }
    return [
      `${startYear}-${startYear + 1}`,
      `${startYear + 1}-${startYear + 2}`,
    ];
  };

  if (loading) return <div>Chargement...</div>;

  return (
    <div className="bg-white dark:bg-slate-300 shadow-md rounded-lg p-4">
      <h3 className="text-xl font-semibold mb-6">
        Paramètres de l'Association
      </h3>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="flex flex-col">
          <label className="text-sm font-medium text-gray-700 mb-1">
            Nom du Club
          </label>
          <input
            type="text"
            name="nom_club"
            value={settings.nom_club}
            onChange={handleChange}
            className="input input-bordered w-full"
            required
          />
        </div>
        <div className="flex flex-col">
          <label className="text-sm font-medium text-gray-700 mb-1">
            Nom raccourci du Club (pour les petits écrans)
          </label>
          <input
            type="text"
            name="shortname_club"
            value={settings.shortname_club}
            onChange={handleChange}
            className="input input-bordered w-full"
            required
          />
        </div>
        <div className="flex flex-col">
          <label className="text-sm font-medium text-gray-700 mb-1">
            Responsable du Club
          </label>
          <input
            type="text"
            name="responsable_club"
            value={settings.responsable_club}
            onChange={handleChange}
            className="input input-bordered w-full"
          />
        </div>
        <div className="flex flex-col md:flex-row gap-6">
          <div className="flex flex-col w-full md:w-1/2">
            <label className="text-sm font-medium text-gray-700 mb-1">
              Lien Logo du Club
            </label>
            <input
              type="text"
              name="logo_club"
              value={settings.logo_club}
              onChange={handleChange}
              className="input input-bordered w-full"
            />
          </div>

          <div className="flex flex-col w-full md:w-1/2 items-center">
            <label className="text-sm font-medium text-gray-700 mb-1">
              Logo du Club
            </label>
            <img
              src={settings.logo_club}
              alt="Logo du Club"
              className="w-32 h-32 object-cover mb-2"
            />
            <Button
  icon={FaUpload}
  text="Changer le logo"
  onClick={openUploadModal}
  className={`btn-primary gap-2 max-w-[200px] ${userRole === "visiteur" ? "cursor-not-allowed opacity-50" : ""}`}
  hoverLabel={userRole === "visiteur" ? "Action désactivée pour les visiteurs" : "Changer le logo"}
  disabled={userRole === "visiteur"}
/>
          </div>
        </div>

        <div className="flex flex-col">
          <label className="text-sm font-medium text-gray-700 mb-1">
            Adresse du Club
          </label>
          <input
            type="text"
            name="adresse_club"
            value={settings.adresse_club}
            onChange={handleChange}
            className="input input-bordered w-full"
          />
        </div>
        <div className="flex flex-col">
          <label className="text-sm font-medium text-gray-700 mb-1">
            Commune du Club
          </label>
          <input
            type="text"
            name="commune_club"
            value={settings.commune_club}
            onChange={handleChange}
            className="input input-bordered w-full"
          />
        </div>
        <div className="flex flex-col">
          <label className="text-sm font-medium text-gray-700 mb-1">
            Téléphone du Club
          </label>
          <input
            type="text"
            name="tel_club"
            value={settings.tel_club}
            onChange={handleChange}
            className="input input-bordered w-full"
          />
        </div>
        <div className="flex flex-col">
          <label className="text-sm font-medium text-gray-700 mb-1">
            Email du Club
          </label>
          <input
            type="email"
            name="mail_club"
            value={settings.mail_club}
            onChange={handleChange}
            className="input input-bordered w-full"
          />
        </div>
        <div className="flex flex-col">
          <label className="text-sm font-medium text-gray-700 mb-1">
            Site Web du Club
          </label>
          <input
            type="text"
            name="siteweb_club"
            value={settings.siteweb_club}
            onChange={handleChange}
            className="input input-bordered w-full"
          />
        </div>
        <div className="flex flex-col">
          <label className="text-sm font-medium text-gray-700 mb-1">
            SIRET du Club
          </label>
          <input
            type="text"
            name="siret_club"
            value={settings.siret_club}
            onChange={handleChange}
            className="input input-bordered w-full"
          />
        </div>
        <div className="flex flex-col">
          <label className="text-sm font-medium text-gray-700 mb-1">
            Facebook du Club
          </label>
          <input
            type="text"
            name="facebook_club"
            value={settings.facebook_club}
            onChange={handleChange}
            className="input input-bordered w-full"
          />
        </div>
        <div className="flex flex-col">
          <label className="text-sm font-medium text-gray-700 mb-1">
            Instagram du Club
          </label>
          <input
            type="text"
            name="instagram_club"
            value={settings.instagram_club}
            onChange={handleChange}
            className="input input-bordered w-full"
          />
        </div>
        <div className="flex flex-col">
          <label className="text-sm font-medium text-gray-700 mb-1">
            Couleur Primaire
          </label>
          <input
            type="color"
            name="primary_color"
            value={settings.primary_color}
            onChange={handleChange}
            className="input input-bordered w-full h-10 p-1"
          />
        </div>
        <div className="flex items-center gap-4">
          <label className="text-sm font-medium text-gray-700">
            Autorisation Renouvellement Adhésion (fin de saison sportive)
          </label>
          <input
            type="checkbox"
            name="autorisation_renouvellement"
            checked={settings.autorisation_renouvellement}
            onChange={handleChange}
            className="toggle toggle-primary"
          />
        </div>
        <div className="flex flex-col">
          <label className="text-sm font-medium text-gray-700 mb-1">
            Saison Active
          </label>
          <select
            name="saison_active"
            value={settings.saison_active}
            onChange={handleChange}
            className="input input-bordered w-full"
          >
            {getSeasonOptions().map((season) => (
              <option key={season} value={season}>
                {season}
              </option>
            ))}
          </select>
        </div>
        <div className="flex justify-end">
          <Button
  text="Mettre à jour"
  type="submit"
  className={`btn-primary gap-2 ${userRole === "visiteur" ? "cursor-not-allowed opacity-50" : ""}`}
  hoverLabel={userRole === "visiteur" ? "Action désactivée pour les visiteurs" : "Mettre à jour"}
  disabled={userRole === "visiteur"}
/>
        </div>
      </form>

      {isUploadModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-slate-200 dark:bg-slate-400 p-6 rounded-lg shadow-xl max-w-lg w-full">
            <h2 className="text-xl font-bold uppercase p-3 mb-4 text-center text-white bg-primary rounded-md shadow">
              Changer le logo du club
            </h2>
            <form
              onSubmit={handleUploadSubmit}
              className="flex flex-col items-center space-y-4 w-full"
            >
              <input
                type="file"
                onChange={handleFileChange}
                accept="image/*"
                className="my-2"
              />
              <div className="flex flex-auto gap-4">
                <Button
                  text="Sauvegarder"
                  type="submit"
                  icon={FaSave}
                  className="gap-2"
                />
                <Button
                  text="Fermer"
                  onClick={closeUploadModal}
                  icon={FaTimes}
                  className="gap-2"
                />
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default SettingsForm;
