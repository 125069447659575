import React, { useState, useEffect } from "react";
import {
  getGroupsList,
  deleteGroup,
  activateGroup,
  updateOrderGroup,
} from "../../../services/groupServices";
import Button from "../../../components/Button/Button";
import ButtonEdit from "../../../components/Button/ButtonEdit";
import ButtonDelete from "../../../components/Button/ButtonDelete";
import { FaPlus } from "react-icons/fa";
import { TbTriangleFilled, TbTriangleInvertedFilled } from "react-icons/tb";
import { ToastContainer, toast } from "react-toastify";
import CreateGroup from "./CreateGroup";
import EditGroupModal from "./EditGroupModal";
import { useUser } from "./../../Home/UserContext"; // Assurez-vous d'importer useUser
import { getRoleFromRoleId } from "./../../../utils/roleHelper"; // Assurez-vous d'importer cette fonction

const SettingsGroups = () => {
  const [groups, setGroups] = useState([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);

  const { user } = useUser(); // Récupère l'utilisateur
  const userRole = getRoleFromRoleId(user?.role_id); // Détermine le rôle de l'utilisateur

  useEffect(() => {
    fetchGroups();
  }, []);

  const fetchGroups = async () => {
    try {
      const data = await getGroupsList();
      setGroups(data);
    } catch (error) {
      console.error("Erreur lors de la récupération des groupes", error);
    }
  };

  const handleDeleteGroup = async (group) => {
    if (group.nombreAdherents > 0) {
      toast.error("Impossible de supprimer un groupe avec des membres actifs.");
      return;
    }

    if (
      window.confirm(
        `Êtes-vous sûr de vouloir supprimer le groupe ${group.nom} ?`
      )
    ) {
      try {
        await deleteGroup(group.id);
        toast.success("Groupe supprimé avec succès");
        fetchGroups();
      } catch (error) {
        toast.error("Erreur lors de la suppression d’un groupe");
        console.error("Erreur lors de la suppression d’un groupe", error);
      }
    }
  };

  const toggleActiveStatus = async (groupId) => {
    try {
      await activateGroup(groupId);
      toast.success("Statut du groupe mis à jour avec succès");
      fetchGroups();
    } catch (error) {
      toast.error("Erreur lors de la mise à jour du statut du groupe");
      console.error("Erreur lors de la mise à jour du statut du groupe", error);
    }
  };

  const openEditModal = (group) => {
    setSelectedGroup(group);
  };

  const closeEditModal = () => {
    setSelectedGroup(null);
  };

  const moveGroupUp = async (index) => {
    if (index === 0) return; // Premier élément ne peut pas être déplacé vers le haut
    const newGroups = [...groups];
    const temp = newGroups[index - 1];
    newGroups[index - 1] = newGroups[index];
    newGroups[index] = temp;
    newGroups[index - 1].ordre_groupe--;
    newGroups[index].ordre_groupe++;
    setGroups(newGroups);
    await updateOrderGroup(newGroups);
    fetchGroups();
  };

  const moveGroupDown = async (index) => {
    if (index === groups.length - 1) return; // Dernier élément ne peut pas être déplacé vers le bas
    const newGroups = [...groups];
    const temp = newGroups[index + 1];
    newGroups[index + 1] = newGroups[index];
    newGroups[index] = temp;
    newGroups[index + 1].ordre_groupe++;
    newGroups[index].ordre_groupe--;
    setGroups(newGroups);
    await updateOrderGroup(newGroups);
    fetchGroups();
  };

  return (
    <div className="bg-white dark:bg-slate-300 shadow-md rounded-lg p-2 max-w-4xl w-full">
      <div className="flex justify-center mb-4">
        <Button
          text="Créer un Groupe"
          icon={FaPlus}
          onClick={() => userRole !== "visiteur" && setIsCreateModalOpen(true)}
          className={`mb-3 gap-2 ${userRole === "visiteur" ? "cursor-not-allowed opacity-50" : ""}`}
          hoverLabel="Créer un nouveau groupe"
          disabled={userRole === "visiteur"} // Désactive le bouton pour les visiteurs
        />
      </div>
      {isCreateModalOpen && (
        <CreateGroup
          isActive={isCreateModalOpen}
          onClose={() => setIsCreateModalOpen(false)}
          refreshGroups={fetchGroups}
        />
      )}
      {selectedGroup && (
        <EditGroupModal
          isActive={Boolean(selectedGroup)}
          onClose={closeEditModal}
          refreshGroups={fetchGroups}
          groupData={selectedGroup}
        />
      )}

      <div className="overflow-x-auto table-scrollable">
        <h2 className="text-2xl font-bold mb-4 text-left dark:text-dark">
          Gestion des Groupes
        </h2>
        <table className="table-auto bg-white dark:bg-slate-300 font-montserrat mx-auto border-collapse border-2 border-slate-300">
          <thead className="bg-gray-800 text-white">
            <tr className="border-collapse border-2 border-slate-300">
              <th className="px-3 py-2 border-b-2 border-gray-200 text-center text-xs font-semibold text-gray-200 uppercase tracking-wider">
                Nom
              </th>
              <th className="px-3 py-2 border-b-2 border-gray-200 text-center text-xs font-semibold text-gray-200 uppercase tracking-wider">
                Ordre
              </th>
              <th className="px-3 py-2 border-b-2 border-gray-200 text-center text-xs font-semibold text-gray-200 uppercase tracking-wider">
                Actif
              </th>
              <th className="px-3 py-2 border-b-2 border-gray-200 text-center text-xs font-semibold text-gray-200 uppercase tracking-wider">
                Nb Adh.
              </th>
              <th className="px-3 py-2 border-b-2 border-gray-200 text-center text-xs font-semibold text-gray-200 uppercase tracking-wider">
                Quota
              </th>
              <th className="px-3 py-2 border-b-2 border-gray-200 text-center text-xs font-semibold text-gray-200 uppercase tracking-wider">
                Enseignants
              </th>
              <th className="px-3 py-2 border-b-2 border-gray-200 text-center text-xs font-semibold text-gray-200 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {groups.map((group, index) => (
              <tr key={group.id}>
                <td
                  className={`px-3 py-4 whitespace-nowrap text-sm font-medium text-gray-900 ${group.color_groupe}`}
                >
                  {group.nom}
                </td>
                <td className="px-3 py-4 whitespace-nowrap text-sm font-medium text-gray-900 flex items-center">
                  {group.ordre_groupe}
                  <div className="flex flex-col ml-2">
                    <button
                      onClick={() => moveGroupUp(index)}
                      className="text-blue-400 hover:text-blue-900"
                    >
                      <TbTriangleFilled />
                    </button>
                    <button
                      onClick={() => moveGroupDown(index)}
                      className="text-blue-400 hover:text-blue-900"
                    >
                      <TbTriangleInvertedFilled />
                    </button>
                  </div>
                </td>
                <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                  <button onClick={() => toggleActiveStatus(group.id)}>
                    {group.is_active ? "✅" : "❌"}
                  </button>
                </td>
                <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                  {group.nombreAdherents}
                </td>
                <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                  {group.quota_groupe}
                </td>
                <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                  <div className="flex flex-col">
                    <span>{group.enseignant1}</span>
                    <span>{group.enseignant2}</span>
                  </div>
                </td>
                <td className="px-3 py-4 whitespace-nowrap text-right text-sm font-medium">
                  <div className="flex">
                    <ButtonEdit
                      onClick={() => userRole !== "visiteur" && openEditModal(group)}
                      className={`mr-2 ${userRole === "visiteur" ? "cursor-not-allowed opacity-50" : ""}`}
                      hoverLabel={userRole === "visiteur" ? "Édition désactivée pour les visiteurs" : "Éditer"}
                      disabled={userRole === "visiteur"}
                    />
                    <ButtonDelete
                      onClick={() => userRole !== "visiteur" && handleDeleteGroup(group)}
                      className={`ml-2 ${userRole === "visiteur" ? "cursor-not-allowed opacity-50" : ""}`}
                      hoverLabel={userRole === "visiteur" ? "Suppression désactivée pour les visiteurs" : "Supprimer"}
                      disabled={userRole === "visiteur"}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <ToastContainer />
    </div>
  );
};

export default SettingsGroups;
