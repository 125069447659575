import React, { useState, useEffect } from "react";
import { useUser } from "./../../Home/UserContext";
import { getRoleFromRoleId } from "./../../../utils/roleHelper";
import {
  getAllEvents,
  deleteEvent,
  getAdherentsByEvent,
  toggleEventActive,
  updateEventImage,
  uploadEventsFile, // Import du service d'upload
} from "./../../../services/eventServices";
import { getSettings } from "./../../../services/settingsServices";
import DashboardNavigation from "./../../DashboardNavigation/DashboardNavigation";
import Button from "./../../../components/Button/Button";
import ButtonEdit from "./../../../components/Button/ButtonEdit";
import ButtonDelete from "./../../../components/Button/ButtonDelete";
import { FaPlusSquare, FaInfoCircle, FaSave, FaTimes } from "react-icons/fa";
import formatDate from "./../../../utils/formatDate";
import CreateEventModal from "./CreateEventModal";
import EditEventModal from "./EditEventModal";
import EventDetailsModalEnseignants from "./EventDetailsModalEnseignants";
import useScrollToTop from "./../../../hooks/useScollToTop";
import ScrollIndicator from "./../../Home/ScrollIndicator";
import { ToastContainer, toast } from "react-toastify";
import InfoBubble from "../../../components/InfoBubble/InfoBubble";


const EventsPage = () => {
  useScrollToTop();
  const { user } = useUser();
  const userRole = getRoleFromRoleId(user?.role_id);

  const [events, setEvents] = useState([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [editableEvent, setEditableEvent] = useState(null);
  const [detailsEvent, setDetailsEvent] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [adherents, setAdherents] = useState([]);
  const [filter, setFilter] = useState("upcoming");
  const [typeFilter, setTypeFilter] = useState("All");
  const [levelFilter, setLevelFilter] = useState("All");
  const [cateFilter, setCateFilter] = useState("All");
  const [sortAscending, setSortAscending] = useState(true);
  const [shortnameClub, setShortnameClub] = useState('');

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const data = await getSettings();
        if (data.length > 0) {
          setShortnameClub(data[0].shortname_club);
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des paramètres', error);
        toast.error('Erreur lors de la récupération des paramètres.');
      }
    };

    fetchSettings();
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    try {
      const data = await getAllEvents();
      setEvents(data);
    } catch (error) {
      console.error("Erreur lors du chargement des événements", error);
    }
  };

  const openEditModal = (event) => {
    setEditableEvent(event);
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
  };

  const openUploadModal = () => {
    setIsUploadModalOpen(true);
  };

  const closeUploadModal = () => {
    setSelectedFile(null);
    setIsUploadModalOpen(false);
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleUploadCalendarSubmit = async (e) => {
    e.preventDefault();
    if (!selectedFile) {
      toast.error("Veuillez sélectionner un fichier.");
      return;
    }

    setIsUploading(true);

    try {
      await uploadEventsFile(selectedFile);
      toast.success("Calendrier des événements uploadé avec succès !");
      closeUploadModal();
      fetchEvents(); // Rafraîchir la liste des événements après l'upload
    } catch (error) {
      console.error("Erreur lors de l'upload du calendrier :", error);
      toast.error("Erreur lors de l'upload du calendrier.");
    } finally {
      setIsUploading(false);
    }
  };

  const openDetailsModal = async (event) => {
    try {
      const adherentsData = await getAdherentsByEvent(event.id);
      console.log("Adhérents récupérés :", adherentsData);
      setAdherents(adherentsData);
      setDetailsEvent(event);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des adhérents et des coachs",
        error
      );
      toast.error("Erreur lors de la récupération des données.");
    }
  };

  const closeDetailsModal = () => {
    setDetailsEvent(null);
  };

  const deleteEventFunc = async (id) => {
    try {
      await deleteEvent(id);
      setEvents(events.filter((event) => event.id !== id));
      toast.success("Événement supprimé avec succès.");
    } catch (error) {
      console.error("Erreur lors de la suppression de l'événement", error);
    }
  };

  const isPastDate = (date) => {
    return (
      new Date(date).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)
    );
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  // Liste des types d'événements (à ajuster selon vos données)
  const typeEvents = [
    "Compétition",
    "Animation",
    "Stage & Regroupement Technique",
    "Passage de Grade",
    "Entraînement Départemental",
    "Stage Sportif Départemental",
    "Tournoi Interclubs",
    "Cours commun Club",
    "Stage Club",
    "Votre Club",
    "Autre",
  ];

  // Liste des niveaux d'événements (à ajuster selon vos données)
  const levelEvents = ["Club", "Départemental", "Régional", "National", "International"];

  // Liste des catégories d'événements
  const cateEvents = [
    "Eveil",
    "Mini-Poussin(e)",
    "Poussin(e)",
    "Benjamin(e)",
    "Minime",
    "Cadet(te)",
    "Junior",
    "Sénior",
    "Vétéran",
  ];

  const toggleSortOrder = () => {
    setSortAscending(!sortAscending);
  };

  const handleToggleActive = async (event) => {
    try {
      const updatedEvent = await toggleEventActive(event.id);
      setEvents((prevEvents) =>
        prevEvents.map((e) =>
          e.id === event.id ? { ...e, is_active: updatedEvent.is_active } : e
        )
      );
    } catch (error) {
      console.error("Erreur lors du changement de l'état de l'événement", error);
      toast.error("Erreur lors du changement de l'état de l'événement.");
    }
  };

  const filteredEvents = events.filter((event) => {
    const eventDate = new Date(event.start).setHours(0, 0, 0, 0);
    const now = new Date().setHours(0, 0, 0, 0);
    const categories = event.cate_event ? event.cate_event.split(",") : [];
    return (
      (filter === "all" ||
        (filter === "upcoming" && eventDate >= now) ||
        (filter === "past" && eventDate < now)) &&
      (typeFilter === "All" || event.type_event === typeFilter) &&
      (levelFilter === "All" || event.level_event === levelFilter) &&
      (cateFilter === "All" || categories.includes(cateFilter))
    );
  });

  const sortedEvents = filteredEvents.sort((a, b) => {
    const dateA = new Date(a.start),
      dateB = new Date(b.start);
    return sortAscending ? dateA - dateB : dateB - dateA;
  });

  return (
    <div className="flex flex-col w-full mx-auto p-2 mt-[130px] sm:mt-[130px] md:mt-[150px] lg:mt-[150px] xl:mt-[150px] dark:text-white">
      <ScrollIndicator />
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        ENSEIGNANTS
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-montserrat uppercase">
        Événements
      </h2>
      <DashboardNavigation role={userRole} />

      <div className="flex flex-col items-center w-full px-4 mt-4">
        <Button
          label="Ajouter un événement"
          text="Ajouter un événement"
          icon={FaPlusSquare}
          onClick={() => setIsCreateModalOpen(true)}
          className="mb-4 mt-4 gap-2"
        />
        <Button
          label="Uploader Calendrier"
          text="Uploader Calendrier"
          icon={FaPlusSquare}
          onClick={() => setIsUploadModalOpen(true)}
          className="mb-8 mt-4 gap-2"
        />
        <div className="flex flex-wrap gap-4 w-full font-montserrat text-normal max-w-[800px] mb-4">
          <select
            onChange={handleFilterChange}
            className="select select-bordered w-full mb-1 dark:bg-slate-200 dark:text-gray-800"
          >
            <option value="all">Tous</option>
            <option value="upcoming">À venir</option>
            <option value="past">Passés</option>
          </select>
          <select
            onChange={(e) => setTypeFilter(e.target.value)}
            className="select select-bordered w-full mb-1 dark:bg-slate-200 dark:text-gray-800"
          >
            <option value="All">Tous les types</option>
            {typeEvents.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>
          <select
            onChange={(e) => setLevelFilter(e.target.value)}
            className="select select-bordered w-full mb-1 dark:bg-slate-200 dark:text-gray-800"
          >
            <option value="All">Tous les niveaux</option>
            {levelEvents.map((level) => (
              <option key={level} value={level}>
                {level}
              </option>
            ))}
          </select>
          <select
            onChange={(e) => setCateFilter(e.target.value)}
            className="select select-bordered w-full mb-1 dark:bg-slate-200 dark:text-gray-800"
          >
            <option value="All">Toutes les catégories</option>
            {cateEvents.map((category) => (
              <option key={category} value={category}>
                {category}
              </option>
            ))}
          </select>
        </div>

        <div className="bg-gray-100 dark:bg-gray-500 shadow-lg w-full max-w-[800px] mx-auto font-montserrat">
          <div className="overflow-x-auto">
            <table className="table-auto bg-white dark:bg-slate-300 font-montserrat max-w-[800px] mx-auto border-collapse border-2 border-slate-300">
              <thead className="bg-gray-800 text-white">
                <tr>
                  <th
                    scope="col"
                    className="px-2 py-3 border-b-2 border-gray-200 text-center text-xs font-semibold uppercase tracking-wider text-white"
                  >
                    Afficher
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-3 border-b-2 border-gray-200 text-center text-xs font-semibold uppercase tracking-wider text-white"
                  >
                    Photo
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-3 border-b-2 border-gray-200 text-center text-xs font-semibold uppercase tracking-wider text-white"
                  >
                    Date
                    <button
                      onClick={toggleSortOrder}
                      className="ml-2 text-xs text-white"
                    >
                      {sortAscending ? "⬇️" : "⬆️"}
                    </button>
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-3 border-b-2 border-gray-200 text-center text-xs font-semibold uppercase tracking-wider text-white"
                  >
                    Nom et Info
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-3 border-b-2 border-gray-200 text-center text-xs font-semibold uppercase tracking-wider text-white"
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {sortedEvents.length > 0 ? (
                  sortedEvents.map((event) => {
                    const isPastEvent = isPastDate(event.start);
                    return (
                      <tr
                        key={event.id}
                        className={`${
                          isPastEvent ? "bg-gray-200" : "bg-white"
                        } hover:bg-gray-100 dark:hover:bg-gray-400 hover:dark:text-white dark:text-black text-sm`}
                      >
                        <td
                          className="px-2 py-3 text-center cursor-pointer"
                          onClick={() => handleToggleActive(event)}
                        >
                          {event.is_active ? "✅" : "❌"}
                        </td>
                        <td className="px-2 py-3 text-center">
                          <img
                            src={event.photo_url}
                            alt={event.titre}
                            className="w-full h-auto object-cover mx-auto min-w-[150px]"
                          />
                          {/* <p className="text-xs mt-2 cursor-pointer" onClick={() => openUploadModal(event)}>Modifier Image</p> */}
                        </td>
                        <td className="px-2 py-3 text-center">
                          {formatDate(event.start)}
                        </td>
                        <td className="px-2 py-3 text-center">
                          <div>{event.titre}</div>
                        </td>
                        <td className="px-2 py-3 flex items-center justify-center space-x-2">
                          <button
                            onClick={() => openDetailsModal(event)}
                            className="w-8 h-8 flex items-center justify-center text-white rounded-md bg-primary p-2 shadow-sm transition duration-300 ease-in-out hover:bg-orange-700"
                          >
                            <FaInfoCircle />
                          </button>
                          <ButtonEdit onClick={() => openEditModal(event)} />
                          <ButtonDelete
                            onClick={() => deleteEventFunc(event.id)}
                          />
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td
                      colSpan="4"
                      className="text-center py-4 px-6 text-gray-800"
                    >
                      Aucun événement.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        {isUploadModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="bg-slate-200 dark:bg-slate-400 p-6 rounded-lg shadow-xl max-w-lg w-full">
              <h2 className="text-xl font-bold uppercase p-3 mb-4 text-center text-white bg-primary rounded-md shadow">
                Uploader le Calendrier des Événements
              </h2>
              <InfoBubble title="Instructions : Vous pouvez uploader le fichier des compétitions de vos comités départementaux au format xls, xlsx ou csv "/>
              <form
                onSubmit={handleUploadCalendarSubmit}
                className="flex flex-col items-center space-y-4 w-full"
              >
                <input
                  type="file"
                  onChange={handleFileChange}
                  accept=".xlsx, .xls, .csv"
                  className="my-2"
                />
                <div className="flex flex-auto gap-4">
                  <Button
                    text="Uploader"
                    type="submit"
                    icon={FaSave}
                    className="gap-2"
                    disabled={isUploading}
                  />
                  <Button
                    text="Fermer"
                    onClick={closeUploadModal}
                    icon={FaTimes}
                    className="gap-2"
                  />
                </div>
              </form>
            </div>
          </div>
        )}

        <EditEventModal
          isOpen={isEditModalOpen}
          onClose={closeEditModal}
          event={editableEvent}
          refreshList={fetchEvents}
        />
        <CreateEventModal
          isOpen={isCreateModalOpen}
          onClose={() => setIsCreateModalOpen(false)}
          refreshList={fetchEvents}
        />
        <EventDetailsModalEnseignants
          event={detailsEvent}
          adherents={adherents}
          isOpen={!!detailsEvent}
          onClose={closeDetailsModal}
        />
      </div>
      <ToastContainer />
    </div>
  );
};

export default EventsPage;
