import jsPDF from "jspdf";

const generateAdherentPdf = (adherent) => {
  const doc = new jsPDF();

  // Dimensions pour la photo
  const imageX = 15; // Position X
  const imageY = 20; // Position Y
  const imageWidth = 40; // Largeur
  const imageHeight = 40; // Hauteur

  // Bandeau supérieur
  const bandX = 15;
  const bandY = 20;
  const bandWidth = 180;
  const bandHeight = 40;

  // Ajout du rectangle pour le bandeau
  doc.setDrawColor(0);
  doc.setFillColor(230, 230, 230);
  doc.rect(bandX, bandY, bandWidth, bandHeight, "F");

  // Ajout de la photo de l'adhérent
  if (adherent.photo_url) {
    doc.addImage(
      adherent.photo_url,
      "JPEG",
      imageX,
      imageY,
      imageWidth,
      imageHeight,
      undefined,
      "FAST"
    );
  }

  // Texte dans le bandeau
  doc.setFontSize(14);
   doc.setFont("helvetica", "bold");
  doc.text("Fiche d'inscription de", 70, 30);
  doc.setFontSize(24); // Taille augmentée pour le Nom et Prénom
  doc.text(`${adherent.nom} ${adherent.prenom}`, 70, 45);

  // Informations personnelles en deux colonnes avec aération accrue
  const startXLabel = 15;
  const startXValue = 70;
  let currentY = 80;
  const lineHeight = 12; // Espace entre les lignes (augmenté)

  doc.setFontSize(12);

  const drawRow = (label, value) => {
    doc.setFont("helvetica", "bold");
    doc.text(label, startXLabel, currentY);
    doc.setFont("helvetica", "normal");
    doc.text(value || "Non renseigné", startXValue, currentY);
    currentY += lineHeight;
  };

  drawRow("Genre :", adherent.genre);
  drawRow(
    "Date de naissance :",
    adherent.date_naissance
      ? new Date(adherent.date_naissance).toLocaleDateString("fr-FR")
      : null
  );

  drawRow("Email principal :", adherent.email);
  drawRow("Email secondaire :", adherent.email_secondaire);
  drawRow("Téléphone principal :", adherent.tel);
  drawRow("Téléphone secondaire :", adherent.tel_secondaire);
  drawRow(
    "Adresse :",
    `${adherent.adresse || ""}, ${adherent.cp || ""} ${adherent.commune || ""}`
  );
  drawRow("Poids :", adherent.poids ? `${adherent.poids} kg` : "Non renseigné");
  drawRow("Numéro de licence :", adherent.licence_number);
  drawRow("Statut :", adherent.statut_inscription);
  drawRow("Saison active :", adherent.saison_active);
  drawRow("Autorisation photo :", adherent.autorisation_photo ? "Oui" : "Non");
  drawRow(
    "Groupes :",
    adherent.Groups.map((group) => group.nom).join(", ")
  );
  drawRow(
    "Utilisateurs associés :",
    adherent.Users.map((user) => `${user.nom} ${user.prenom}`).join(", ")
  );
  drawRow("Notes :", adherent.notes_adherent || "RAS");

  // Ajout d'une ligne de signature
  doc.line(15, currentY, 195, currentY);
  doc.setFont("helvetica", "italic");
  doc.text("Signature de l'adhérent ou tuteur légal", 105, currentY + 8, null, null, "center");

  // Génération du fichier PDF
  doc.save(`Fiche_Adherent_${adherent.nom}_${adherent.prenom}.pdf`);
};

export default generateAdherentPdf;
