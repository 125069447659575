import React, { useState, useEffect } from "react";
import { FaPlus } from "react-icons/fa";
import Button from "./../../../components/Button/Button";
import ButtonEdit from "./../../../components/Button/ButtonEdit";
import ButtonDelete from "./../../../components/Button/ButtonDelete";
import { useUser } from "./../../Home/UserContext";
import { getRoleFromRoleId } from "./../../../utils/roleHelper";
import DashboardNavigation from "./../../DashboardNavigation/DashboardNavigation";
import {
  getRoles,
  getAllUsers,
  getLatestUsers,
  deleteUser as deleteUserAPI,
  assignAdherentToUser,
} from "./../../../services/userServices";
import useScrollToTop from "../../../hooks/useScollToTop";
import ScrollIndicator from "./../../Home/ScrollIndicator";
import AddUserModal from "./AddUserModal";
import EditUserModal from "./EditUserModal";
import ManageAdherentsModal from "./AdherentManagementModal";
import { ToastContainer, toast } from "react-toastify";

const UserListing = () => {
  useScrollToTop();
  const [users, setUsers] = useState([]);
  const [displayedUsers, setDisplayedUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [roles, setRoles] = useState({});
  const { user } = useUser();
  const userRole = getRoleFromRoleId(user?.role_id);
  const userId = user?.userId;
  const [showModal, setShowModal] = useState(false); // État pour contrôler l'ouverture de la modale
  const [showEditModal, setShowEditModal] = useState(false);
  const [showManageAdherentsModal, setShowManageAdherentsModal] =
    useState(false);
  const [editUserId, setEditUserId] = useState(null);
  const [selectedAdherent, setSelectedAdherent] = useState(null); // État pour stocker l'adhérent sélectionné
  const [currentUserId, setCurrentUserId] = useState(null);

  const fetchData = async () => {
    try {
      const usersData = await getAllUsers();
      const rolesData = await getRoles();
      if (!Array.isArray(rolesData)) {
        throw new Error("Expected rolesData to be an array");
      }
      const rolesMap = rolesData.reduce((acc, role) => {
        acc[role.id] = role.nom;
        return acc;
      }, {});
      setUsers(usersData);
      setDisplayedUsers(usersData);
      setRoles(rolesMap);
    } catch (error) {
      console.error("Erreur lors du chargement des données:", error);
      toast.error("Erreur lors du chargement des données");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSearchChange = (e) => {
    const searchTermLocal = e.target.value;
    setSearchTerm(searchTermLocal);
    if (!searchTermLocal) {
      setDisplayedUsers(users);
    } else {
      filterUsers(searchTermLocal);
    }
  };

  const filterUsers = (term) => {
    const filteredUsers = users.filter(
      (user) =>
        user.nom.toLowerCase().includes(term.toLowerCase()) ||
        user.prenom.toLowerCase().includes(term.toLowerCase())
    );
    setDisplayedUsers(filteredUsers);
  };

  // Fonction pour supprimer un utilisateur
  const deleteUser = async (userId) => {
    if (userRole === "visiteur") {
      toast.error(
        "Les visiteurs ne sont pas autorisés à supprimer des utilisateurs."
      );
      return;
    }

    if (
      window.confirm(
        `Êtes-vous sûr de vouloir supprimer cet utilisateur ? ID: ${userId}`
      )
    ) {
      try {
        const message = await deleteUserAPI(userId);
        toast.success(message);
        setUsers(users.filter((user) => user.id !== userId));
      } catch (error) {
        console.error("Erreur lors de la suppression de l'utilisateur:", error);
        toast.error("Erreur lors de la suppression de l'utilisateur");
      }
    }
  };

  const getRecentUsers = async () => {
    try {
      const recentUsers = await getLatestUsers();
      setDisplayedUsers(recentUsers);
    } catch (error) {
      toast.error("Erreur lors de la récupération des derniers utilisateurs");
    }
  };

  const getAllUsersAgain = async () => {
    setDisplayedUsers(users);
  };

  const handleAssignAdherent = async (userId) => {
    try {
      const adherentId = "";
      const message = await assignAdherentToUser(userId, adherentId);
      setShowModal(true);
      toast.success(message);
    } catch (error) {
      console.error(
        "Erreur lors de l'assignation de l'adhérent à l'utilisateur:",
        error
      );
      toast.error("Erreur lors de l'assignation de l'adhérent à l'utilisateur");
    }
  };

  // Fonction pour afficher le rôle sous forme de badge
  const getRoleDisplay = (roleId) => {
    switch (roleId) {
      case 1:
        return { name: "Administrateur", className: "bg-red-500 text-white" };
      case 2:
        return { name: "Utilisateur", className: "bg-blue-500 text-white" };
      case 3:
        return { name: "Enseignant", className: "bg-green-500 text-white" };
      case 4:
        return { name: "Visiteur", className: "bg-orange-500 text-white" };
      default:
        return { name: "Rôle inconnu", className: "bg-gray-500 text-white" };
    }
  };

  const handleAddUser = () => {
    if (userRole !== "visiteur") {
      setShowModal(true);
    }
  };

  const openEditModal = (userId) => {
    if (userRole !== "visiteur") {
      setEditUserId(userId);
      setShowEditModal(true);
    }
  };

  const openManageAdherentsModal = (userId) => {
    if (userRole !== "visiteur") {
      setEditUserId(userId);
      setShowManageAdherentsModal(true);
    }
  };

  return (
    <div className="flex flex-col w-full mx-auto p-2 mt-[130px] sm:mt-[130px] md:mt-[150px] lg:mt-[150px] xl:mt-[150px] dark:text-white">
      <ScrollIndicator />
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        ADMINISTRATEUR
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-montserrat uppercase">
        Listing Utilisateurs
      </h2>
      <DashboardNavigation role={userRole} />

      <div className="shadow-xl w-full max-w-[1280px] mx-auto">
        <Button
          text="Ajouter Utilisateur"
          onClick={handleAddUser}
          icon={FaPlus}
          className={`w-full gap-2 ${
            userRole === "visiteur" ? "cursor-not-allowed opacity-50" : ""
          }`}
          disabled={userRole === "visiteur"}
        />
        <AddUserModal
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          refreshList={() => {
            const fetchUsers = async () => {
              const usersData = await getAllUsers();
              setUsers(usersData);
              setDisplayedUsers(usersData);
            };
            fetchUsers();
          }}
        />
        <EditUserModal
          isOpen={showEditModal}
          userId={editUserId}
          onClose={() => setShowEditModal(false)}
          refreshList={fetchData}
        />
        <input
          className="input input-bordered mt-4 mb-4 w-full"
          type="text"
          placeholder="Rechercher..."
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <div className="flex space-x-4 mb-4">
          <Button
            text="Afficher les derniers inscrits"
            onClick={getRecentUsers}
            className="btn btn-primary"
          />
          <Button
            text="Afficher Tous"
            onClick={getAllUsersAgain}
            className="btn btn-secondary"
          />
        </div>
      </div>
      <div className="overflow-x-auto shadow-xl">
        <table className="table bg-white dark:bg-slate-300 font-montserrat max-w-[1280px] mx-auto border-collapse border-2 border-slate-300 mb-8">
          <thead className="bg-gray-800 text-white">
            <tr>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Photo
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Nom Prénom
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Email
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Rôle
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Gestion Adhérent
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {displayedUsers.map((user, index) => (
              <tr key={user.user_id} className="text-left">
                <td>
                  <img
                    className="rounded-full w-10 h-10 object-cover"
                    src={user.photoURL || "/img/user_avatar.jpg"}
                    alt="Avatar"
                  />
                </td>
                <td className="text-left">
                  {user.nom} {user.prenom}
                </td>
                <td className="text-left">{user.email}</td>
                <td>
                  <span
                    className={`badge ${
                      getRoleDisplay(user.role_id).className
                    }`}
                  >
                    {getRoleDisplay(user.role_id).name}
                  </span>
                </td>
                <td className="text-left">
                  {user.adherents && user.adherents.length > 0 ? (
                    user.adherents.map((adherent, idx) => (
                      <div key={`${user.user_id}-${idx}`}>
                        {adherent.nom} {adherent.prenom}
                      </div>
                    ))
                  ) : (
                    <div>Aucun</div>
                  )}
                </td>
                <td className="flex items-center gap-2">
                  <ButtonEdit
                    onClick={() => openEditModal(user.id)}
                    className={`${
                      userRole === "visiteur"
                        ? "cursor-not-allowed opacity-50"
                        : ""
                    }`}
                    hoverLabel="Éditer"
                    disabled={userRole === "visiteur"}
                  />
                  <button
                    className={`w-8 h-8 bg-green-500 text-white rounded-md shadow-sm transition duration-300 ease-in-out hover:bg-green-700 flex items-center justify-center ${
                      userRole === "visiteur"
                        ? "cursor-not-allowed opacity-50"
                        : ""
                    }`}
                    title="Gérer Adhérents"
                    onClick={() => openManageAdherentsModal(user.id)}
                    disabled={userRole === "visiteur"}
                  >
                    +/-
                  </button>
                  <ButtonDelete
                    onClick={() => deleteUser(user.id)}
                    className={`${
                      userRole === "visiteur"
                        ? "cursor-not-allowed opacity-50"
                        : ""
                    }`}
                    hoverLabel="Supprimer"
                    disabled={userRole === "visiteur"}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <ManageAdherentsModal
        isOpen={showManageAdherentsModal}
        onClose={() => setShowManageAdherentsModal(false)}
        userId={editUserId}
        refresh={fetchData} // Assurez-vous que cette fonction recharge les données pertinentes
      />
      <ToastContainer />
    </div>
  );
};

export default UserListing;
