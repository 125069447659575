import React, { useState, useEffect } from 'react';
import { getSettings, updateSettings } from '../../../services/settingsServices';
import { getAllDisciplinegenerales } from '../../../services/disciplinegeneraleSettings';
import { toast } from 'react-toastify';
import Button from '../../../components/Button/Button';
import { useUser } from "../../Home/UserContext"; // Importer useUser
import { getRoleFromRoleId } from "../../../utils/roleHelper";

const ModuleSettingsForm = () => {
  const [settings, setSettings] = useState({
    display_boutique: true,
    display_chat: true,
    display_news: true,
    display_galerie: true,
    display_lexique: true,
    display_techniques: true,
    display_arbitrage: true,
    display_appels: true,
    display_todolist: true,
    display_coaching: true,
    display_heures_enseignants: true,
    display_inventaire: true,
    display_chat_admin: true,
    display_messages_admin: true,
  });
  const [loading, setLoading] = useState(true);
    const { user } = useUser(); // Récupérer l'utilisateur
  const userRole = getRoleFromRoleId(user?.role_id);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const settingsData = await getSettings();
        if (settingsData.length > 0) {
          setSettings(settingsData[0]);
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des données:', error);
      }
      setLoading(false);
    };

    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name } = e.target;
    setSettings((prevSettings) => ({
      ...prevSettings,
      [name]: !prevSettings[name],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateSettings(settings);
      toast.success('Paramètres mis à jour avec succès');
      window.location.reload();
    } catch (error) {
      toast.error('Erreur lors de la mise à jour des paramètres');
    }
  };

  if (loading) return <div>Chargement...</div>;

  const modules = [
    { name: 'Boutique', key: 'display_boutique' },
    { name: 'Chat', key: 'display_chat' },
    { name: 'News', key: 'display_news' },
    { name: 'Galerie Photo', key: 'display_galerie' },
    { name: 'Appels', key: 'display_appels' },
    { name: 'ToDoList', key: 'display_todolist' },
    { name: 'Coaching', key: 'display_coaching' },
    { name: 'Heures Enseignants', key: 'display_heures_enseignants' },
    { name: 'Inventaire', key: 'display_inventaire' },
    { name: 'Chat Admin', key: 'display_chat_admin' },
    { name: 'Messages Admin', key: 'display_messages_admin' },
  ];

  // Ajouter les modules Lexique, Techniques et Arbitrage seulement si leur affichage est activé dans les settings
  if (settings.display_lexique) {
    modules.push({ name: 'Lexique', key: 'display_lexique' });
  }
  if (settings.display_techniques) {
    modules.push({ name: 'Techniques', key: 'display_techniques' });
  }
  if (settings.display_arbitrage) {
    modules.push({ name: 'Arbitrage', key: 'display_arbitrage' });
  }

  return (
    <div className="bg-white dark:bg-slate-300 shadow-md rounded-lg p-4 max-w-4xl">
      <h3 className="text-xl font-semibold mb-6">Paramètres des Modules</h3>
      <form onSubmit={handleSubmit} className="space-y-4">
        <table className="w-full table-auto border-collapse">
          <thead>
            <tr className="bg-slate-300 text-center">
              <th className="border px-4 py-2">Modules</th>
              <th className="border px-4 py-2 text-center">Afficher</th>
              <th className="border px-4 py-2 text-center">Masquer</th>
            </tr>
          </thead>
          <tbody>
            {modules.map((module) => (
              <tr key={module.key}>
                <td className="border px-4 py-2">{module.name}</td>
                <td
                  className="border px-4 py-2 text-center"
                  style={{ backgroundColor: settings[module.key] ? 'green' : '' }}
                >
                  <label className="flex items-center justify-center">
                    <input
                      type="checkbox"
                      name={module.key}
                      value="true"
                      checked={settings[module.key] === true}
                      onChange={handleChange}
                      className="input-checkbox"
                    />
                  </label>
                </td>
                <td
                  className="border px-4 py-2 text-center"
                  style={{ backgroundColor: settings[module.key] ? '' : 'red' }}
                >
                  <label className="flex items-center justify-center">
                    <input
                      type="checkbox"
                      name={module.key}
                      value="false"
                      checked={settings[module.key] === false}
                      onChange={handleChange}
                      className="input-checkbox"
                    />
                  </label>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="flex justify-end">
          <Button
            text="Mettre à jour"
            type="submit"
            className={`btn-primary gap-2 ${
              userRole === "visiteur" ? "cursor-not-allowed opacity-50" : ""
            }`}
            disabled={userRole === "visiteur"}
          />
        </div>
      </form>
    </div>
  );
};

export default ModuleSettingsForm;
