import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import useScrollToTop from "../../../hooks/useScollToTop";
import { getGalleryById } from "../../../services/gallerieServices";
import Button from "../../../components/Button/Button";
import { useUser } from "../../Home/UserContext";
import { getRoleFromRoleId } from "../../../utils/roleHelper";
import { FaPlus, FaMinus } from "react-icons/fa";
import AddPhotoToAlbumModal from "./AddPhotoToAlbumModal"; // Assurez-vous que le chemin est correct
import RemovePhotoFromAlbumModal from "./RemovePhotoFromAlbumModal"; // Assurez-vous que le chemin est correct
import { addPhoto } from "../../../services/photoServices"; // Importez la fonction addPhoto
import { ToastContainer } from "react-toastify";

const AlbumGaleriePage = () => {
    useScrollToTop();

    const navigate = useNavigate();
    const { id } = useParams();
    const { user } = useUser();
    const userRole = getRoleFromRoleId(user?.role_id);
    const [gallery, setGallery] = useState(null);
    const [selectedImg, setSelectedImg] = useState(null);
    const [isAddPhotoModalOpen, setIsAddPhotoModalOpen] = useState(false);
    const [isRemovePhotoModalOpen, setIsRemovePhotoModalOpen] = useState(false);
    const [newPhotoId, setNewPhotoId] = useState(null);

    const fetchGallery = async () => {
        const response = await getGalleryById(Number(id));
        console.log(response); // Affichez la réponse pour voir ce que vous recevez
        if (response && response.id) {
            setGallery(response); // Directement utiliser l'objet réponse
        } else {
            setGallery(null);
        }
    };

    useEffect(() => {
        fetchGallery();
    }, [id]);

    if (!gallery)
        return (
            <div className="container mx-auto mt-5 p-4 text-black dark:text-white">
                Album introuvable
            </div>
        );

    const handleAddPhotoClick = async () => {
        try {
            const newPhoto = await addPhoto({ gallerie_id: id, is_active: true });
            setNewPhotoId(newPhoto.id);
            setIsAddPhotoModalOpen(true);
        } catch (error) {
            console.error("Erreur lors de l'ajout de la photo:", error);
        }
    };

    const handleClick = (img) => {
        setSelectedImg(img);
    };

    return (
        <div className="flex flex-col w-full mx-auto p-2 mt-[130px] sm:mt-[130px] md:mt-[150px] lg:mt-[150px] xl:mt-[150px] dark:text-white">
            <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
                UTILISATEURS
            </h2>
            <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-montserrat uppercase">
                Galeries Photos
            </h2>
            <div className="flex flex-col max-w-[1280px] mx-auto">
                <Button
                    text="Retour"
                    onClick={() => navigate(-1)}
                    className="mb-4 mt-2"
                />
                <h2 className="text-primary mt-2 mb-2 uppercase font-normal">
                    {gallery.category}
                </h2>
                <h2 className="text-2xl font-bold mb-4">{gallery.titre}</h2>
                <p className="text-black dark:text-white italic text-justify mb-5">
                    {gallery.description}
                </p>
                <p className="text-black dark:text-white italic text-justify mb-5">
                    {gallery.photos.length} photo{gallery.photos.length > 1 && "s"} dans
                    cette galerie
                </p>

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
                    {gallery.photos.map((photo, index) => (
                        <div key={index} className="mb-4">
                            <img
                                src={photo.photo_url}
                                alt={` ${index + 1}`}
                                className="cursor-pointer object-cover w-full h-48 transition-transform duration-200 hover:scale-105"
                                onClick={() => handleClick(photo.photo_url)}
                            />
                            <p className="text-black dark:text-white text-xs italic text-justify mt-2">
                                {photo.description}
                            </p>
                        </div>
                    ))}

                    {(userRole === "admin" || userRole === "enseignant"|| userRole === "visiteur") && (
                        <>
                            <div
                                onClick={handleAddPhotoClick}
                                className="cursor-pointer bg-blue-200 dark:bg-gray-700 shadow-xl rounded-lg p-4 text-center relative mb-4 flex flex-col items-center justify-center h-48 transition-transform duration-200 hover:scale-105"
                            >
                                <FaPlus className="text-6xl bg-primary text-white rounded-full p-4" />
                                <p className="text-sm font-medium mt-4">Ajouter une photo</p>
                            </div>

                            <div
                                onClick={() => setIsRemovePhotoModalOpen(true)}
                                className="cursor-pointer bg-blue-200 dark:bg-gray-700 shadow-xl rounded-lg p-4 text-center relative mb-4 flex flex-col items-center justify-center h-48 transition-transform duration-200 hover:scale-105"
                            >
                                <FaMinus className="text-6xl bg-primary text-white rounded-full p-4" />
                                <p className="text-sm font-medium mt-4">Retirer une photo</p>
                            </div>
                        </>
                    )}
                </div>

                {selectedImg && (
                    <div
                        className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center"
                        onClick={() => setSelectedImg(null)}
                    >
                        <img
                            src={selectedImg}
                            alt="Selected"
                            className="max-w-[90%] max-h-[90%]"
                        />
                    </div>
                )}
            </div>
            {isAddPhotoModalOpen && (
                <AddPhotoToAlbumModal
                    isOpen={isAddPhotoModalOpen}
                    onClose={() => {
                        setIsAddPhotoModalOpen(false);
                        fetchGallery(); // Refresh the gallery after adding a photo
                    }}
                    galleryId={id}
                    photoId={newPhotoId}
                />
            )}
            {isRemovePhotoModalOpen && (
                <RemovePhotoFromAlbumModal
                    isOpen={isRemovePhotoModalOpen}
                    onClose={() => {
                        setIsRemovePhotoModalOpen(false);
                        fetchGallery(); // Refresh the gallery after removing a photo
                    }}
                    galleryId={id}
                />
            )}
            <ToastContainer />
        </div>
    );
};

export default AlbumGaleriePage;
