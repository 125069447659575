import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getLatestNews } from "./../../services/newsServices"; // Assurez-vous que le chemin est correct
import Button from "./../../components/Button/Button";
import { useUser } from "./../Home/UserContext";

const LatestNews = () => {
  const [newsList, setNewsList] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const navigate = useNavigate();
  const { user } = useUser();

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const latestNews = await getLatestNews(); // Fonction API pour obtenir les dernières actualités
        const activeNews = latestNews.filter((news) => news.is_active); // Filtrer pour garder seulement les actualités actives
        setNewsList(activeNews.slice(0, 3)); // Gardez seulement les trois dernières actualités actives
      } catch (error) {
        console.error("Failed to fetch news", error);
      }
    };

    fetchNews();
  }, []);

  const handleReadMore = (newsId) => {
    navigate(`/actualites`);
  };

  // Fonction pour ouvrir la modale avec l'image sélectionnée
  const openModal = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  // Fonction pour fermer la modale
  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <div className="container mx-auto">
      <h2 className="text-3xl font-extrabold text-center mt-2 font-montserrat uppercase">
        Dernières Actualités
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 p-4">
        {newsList.map((news) => (
          <div
            key={news.id}
            className="flex flex-col bg-slate-100 dark:bg-slate-500 shadow-md rounded-lg p-4 justify-between h-full"
          >
            <div>
              <img
                src={news.image_url || '/img/no-image.jpg'} // Si pas d'image, utilise no-image.jpg
                alt={news.title || 'Image non disponible'} // Gère aussi le cas où le titre est absent
                className="w-full h-64 object-cover rounded-t-lg cursor-pointer"
                onClick={() => openModal(news.image_url || '/img/no-image.jpg')} // Ouvre la modale avec l'image par défaut si aucune image
              />
              <h3 className="text-xl font-bold mb-2 mt-2">{news.title}</h3>
              <h5 className="text-xs mb-3 text-black bg-gray-200 p-2">
                {news.author?.prenom} {news.author?.nom}{" "}
                {/* Assurez-vous que les données de l'auteur sont incluses */}
              </h5>
              <div
                className="text-sm text-gray-600 dark:text-slate-300 mb-4"
                dangerouslySetInnerHTML={{ __html: news.content }}
              />
            </div>
            {user && (
              <Button
                text="Voir plus d'actualités"
                onClick={() => handleReadMore(news.id)}
                className="self-center mt-auto"
              />
            )}
          </div>
        ))}
      </div>

      {/* Modale pour afficher l'image en grand */}
      {selectedImage && (
        <div
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
          onClick={closeModal}
        >
          <div className="relative">
            <img
              src={selectedImage}
              alt="Agrandissement"
              className="max-w-full max-h-full rounded-lg"
            />
            <button
              className="absolute top-2 right-2 text-white text-2xl"
              onClick={closeModal}
              aria-label="Fermer"
            >
              &times;
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default LatestNews;
