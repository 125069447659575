import React, { useState, useEffect } from "react";
import {
  FaFacebookF,
  FaInstagram,
  FaSignInAlt,
  FaSignOutAlt,
  FaEnvelope,
  FaPhone,
  FaSun,
  FaMoon,
  FaRegEnvelope,
  FaBell,
} from "react-icons/fa";
import { MdOutlineMessage } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useNotification } from "./../../../hooks/NotificationContext";
import { useMessage } from "./../../../hooks/MessageContext";
import { useContactForm } from "./../../../hooks/ContactFormContext";
import { useUser } from "../UserContext";

const TopBar = ({ onLogout }) => {
  const navigate = useNavigate();
  const separator = (
    <div className="border-l border-gray-500 mx-2 h-full"></div>
  );
  const iconStyle =
    "cursor-pointer h-4 w-4 hover:text-primary transition duration-300 mx-2";
  const { unreadCount } = useNotification();
  const { unreadMessageCount } = useMessage();
  const { unreadCountContactForm } = useContactForm();
  const { user, refreshUser } = useUser();
  const [userInfo, setUserInfo] = useState({
    photoURL: user?.photoURL,
    nom: user?.nom,
    prenom: user?.prenom,
  });

  useEffect(() => {
    if (user) {
      setUserInfo({
        photoURL: user.photoURL,
        nom: user.nom,
        prenom: user.prenom,
      });
    }
  }, [user]); // Écoutez les changements de l'objet utilisateur

  const handleLogout = () => {
    onLogout();
    // Vous pouvez aussi vouloir réinitialiser l'info utilisateur ici
    navigate("/");
    setUserInfo({ photoURL: null, nom: "", prenom: "" });
  };

  const toggleTheme = () => {
    const theme = document.documentElement.classList.toggle("dark")
      ? "dark"
      : "light";
    localStorage.setItem("theme", theme);
  };

  // Fonction pour naviguer vers la page de profil
  const goToProfile = () => {
    navigate("/profile");
  };

  return (
    <div className="bg-black text-white h-12 px-4 flex items-center justify-end">
      {window.innerWidth < 480 ? null : (
        <FaEnvelope
          className={iconStyle}
          onClick={() => (window.location = "mailto:usbjudo33@gmail.com")}
          title="E-mail"
        />
      )}
      {window.innerWidth < 480 ? null : separator}
      {window.innerWidth < 480 ? null : (
        <FaPhone
          className={iconStyle}
          onClick={() => (window.location = "tel:+33680727707")}
          title="Téléphone"
        />
      )}
      {window.innerWidth < 480 ? null : separator}
      {window.innerWidth < 480 ? null : (
        <FaFacebookF
          className={iconStyle}
          onClick={() =>
            window.open("https://www.facebook.com/usbouscatjudo", "_blank")
          }
          title="Facebook"
        />
      )}
      {window.innerWidth < 480 ? null : (
        <FaInstagram
          className={iconStyle}
          onClick={() =>
            window.open("https://www.instagram.com/usbouscatjudo/", "_blank")
          }
          title="Instagram"
        />
      )}
      {window.innerWidth < 480 ? null : separator}
      {user ? (
        <>
          <img
            src={userInfo.photoURL || "/img/user_avatar.jpg"}
            alt="Avatar"
            className="h-8 w-8 rounded-full mx-2 cursor-pointer object-cover"
            onClick={goToProfile}
          />
          <span
            className="hidden md:block text-sm mr-2 cursor-pointer"
            onClick={goToProfile}
          >
            {userInfo.nom} {userInfo.prenom}
          </span>
          <FaSignOutAlt
            className={iconStyle}
            onClick={handleLogout}
            title="Deconnexion"
          />
          {separator}
          <div className="relative" onClick={() => navigate("/messages")}>
            <FaRegEnvelope className={iconStyle} title="Messages" />
            <span className="absolute -top-2 -right-2 bg-red-600 text-white text-xs rounded-full px-2 py-0.5">
              {unreadMessageCount}
            </span>
          </div>
          {separator}
          <div
            className="relative"
            onClick={() => navigate("/tableau-de-bord")}
          >
            <FaBell className={iconStyle} title="Notifications" />
            <span className="absolute -top-2 -right-2 bg-red-600 text-white text-xs rounded-full px-2 py-0.5">
              {unreadCount}
            </span>
          </div>

          {(user.role_id === 1 || user.role_id === 4) && (
            <>
              {separator}
              <div
                className="relative"
                onClick={() => navigate("/tableau-de-bord")}
              >
                <MdOutlineMessage
                  className={iconStyle}
                  title="Messages du Formulaire"
                />
                <span className="absolute -top-2 -right-2 bg-red-600 text-white text-xs rounded-full px-2 py-0.5">
                  {unreadCountContactForm}
                </span>
              </div>
            </>
          )}
          {!user.role_id && separator}
        </>
      ) : (
        <FaSignInAlt
          className={iconStyle}
          onClick={() => navigate("/login")}
          title="Connexion"
        />
      )}
      {separator}
      <label className="swap swap-rotate cursor-pointer ml-1">
        <input type="checkbox" onChange={toggleTheme} />
        <FaSun className="swap-off w-5 h-5" />
        <FaMoon className="swap-on w-5 h-5" />
      </label>
    </div>
  );
};

export default TopBar;
