import React, { useState, useEffect } from "react";
import StatisticsSection from "./StatisticsSection"; // Assure-toi que le chemin est correct.
import { getGroupsCount } from "../../services/groupServices";
import {
  getEnseignantsCount,
} from "../../services/adherentServices";
import {
  comptageInscriptionsTotales,
  comptageStatutLicencie,
} from "../../services/dashboardServices";

const HeaderCount = () => {
  const [stats, setStats] = useState({
    licencies: 0,
    groupes: 0,
    enseignants: 0,
    inscritsEtCoursEssai: 0,
  });

  // On récupère le nombre de groupes
  useEffect(() => {
    const fetchGroupsCount = async () => {
      try {
        const data = await getGroupsCount();
        if (data) {
          setStats((prevStats) => ({
            ...prevStats,
            groupes: data,
          }));
        } else {
          console.error("Aucune donnée reçue");
        }
      } catch (error) {
        console.error("Erreur lors du chargement du nombre de groupes:", error);
      }
    };

    fetchGroupsCount();
  }, []);

  // On récupère le nombre de licenciés via comptageStatutLicencie
  useEffect(() => {
    const fetchAdherentsLicencies = async () => {
      try {
        const data = await comptageStatutLicencie();
        if (data && typeof data.nombreAdherents === "number") {
          setStats((prevStats) => ({
            ...prevStats,
            licencies: data.nombreAdherents,
          }));
        } else {
          console.error("Données reçues incorrectes ou manquantes");
        }
      } catch (error) {
        console.error(
          "Erreur lors du chargement du nombre de licenciés:",
          error
        );
      }
    };

    fetchAdherentsLicencies();
  }, []);

  // On récupère le nombre d'enseignants
  useEffect(() => {
    const fetchEnseignantsCount = async () => {
      try {
        const data = await getEnseignantsCount();
        if (data) {
          setStats((prevStats) => ({
            ...prevStats,
            enseignants: data,
          }));
        } else {
          console.error("Aucune donnée reçue");
        }
      } catch (error) {
        console.error(
          "Erreur lors du chargement du nombre d'enseignants:",
          error
        );
      }
    };

    fetchEnseignantsCount();
  }, []);

  // On récupère le nombre d'inscrits et cours d'essai
  useEffect(() => {
    const fetchInscritsCount = async () => {
      try {
        const data = await comptageInscriptionsTotales();
        if (data) {
          setStats((prevStats) => ({
            ...prevStats,
            inscritsEtCoursEssai: data.nombreInscrits,
          }));
        } else {
          console.error("Aucune donnée reçue");
        }
      } catch (error) {
        console.error("Erreur lors du chargement des inscrits et cours d'essai:", error);
      }
    };

    fetchInscritsCount();
  }, []);

  return (
    <div className="py-4 bg-gray-100 dark:bg-gray-700 dark:text-white">
      <h2 className="text-3xl font-extrabold text-center mb-4 font-montserrat">
        QUELQUES CHIFFRES
      </h2>
      <StatisticsSection
        licencies={stats.licencies}
        groupes={stats.groupes}
        enseignants={stats.enseignants}
        inscritsEtCoursEssai={stats.inscritsEtCoursEssai}
      />
    </div>
  );
};

export default HeaderCount;
