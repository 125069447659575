// src/utils/roleHelper.js
export const getRoleFromRoleId = (roleId) => {
  const roleMap = {
    1: "admin",
    2: "user",
    3: "enseignant",
    4: "visiteur",
  };
  return roleMap[roleId] || "user"; // Fallback à 'user' si `roleId` est inconnu
};
