import React, { useState, useEffect } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";

const DashboardNavigation = ({ role }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [currentIndex, setCurrentIndex] = useState(-1);
  const [currentPaths, setCurrentPaths] = useState([]);

  const paths = {
    user: [
      "/tableau-de-bord",
      "/dossier-d-inscription",
      "/fiche-adherent",
      "/calendrier",
      "/messages",
      "/chat",
      "/boutique",
      "/commandes-&-reglements",
      "/actualites",
      "/galerie-photos",
      "/lexique",
      "/arbitrage-judo",
      "/judo-score",
      "/techniques-judo",
    ],
    enseignant: [
      "/tableau-de-bord",
      "/dossier-d-inscription",
      "/fiche-adherent",
      "/calendrier",
      "/messages",
      "/chat",
      "/boutique",
      "/commandes-&-reglements",
      "/actualites",
      "/galerie-photos",
      "/lexique",
      "/arbitrage-judo",
      "/judo-score",
      "/techniques-judo",
      "/listing-adherents",
      "/appel-presence",
      "/passage-ceinture",
      "/grade-cn",
      "/suivi-competitions",
      "/evenements",
      "/palmares",
      "/todolist",
    ],
    admin: [
      "/tableau-de-bord",
      "/dossier-d-inscription",
      "/fiche-adherent",
      "/calendrier",
      "/messages",
      "/chat",
      "/boutique",
      "/commandes-&-reglements",
      "/actualites",
      "/galerie-photos",
      "/lexique",
      "/arbitrage-judo",
      "/judo-score",
      "/techniques-judo",
      "/listing-adherents",
      "/appel-presence",
      "/passage-ceinture",
      "/grade-cn",
      "/suivi-competitions",
      "/evenements",
      "/palmares",
      "/todolist",  
      "/dashboard-adherents",
      "/dashboard-comptabilite",
      "/details-adherent",
      "/listing-utilisateurs",
      "/listing-adherents-admin",
      "/reglements-inscriptions",
      "/dossiers-d-inscription",
      "/achats-boutique",
      "/articles-admin",
      "/depenses-recettes",
      "/coachings",
      "/heures-enseignement",
      "/inventaire",
      "/chat-page-admin",
      "/message-admin",
      "/parametres",
    ],
    visiteur: [
      "/tableau-de-bord",
      "/dossier-d-inscription",
      "/fiche-adherent",
      "/calendrier",
      "/messages",
      "/chat",
      "/boutique",
      "/commandes-&-reglements",
      "/actualites",
      "/galerie-photos",
      "/lexique",
      "/arbitrage-judo",
      "/judo-score",
      "/techniques-judo",
      "/listing-adherents",
      "/appel-presence",
      "/passage-ceinture",
      "/grade-cn",
      "/suivi-competitions",
      "/evenements",
      "/palmares",
      "/todolist",  
      "/dashboard-adherents",
      "/dashboard-comptabilite",
      "/details-adherent",
      "/listing-utilisateurs",
      "/listing-adherents-admin",
      "/reglements-inscriptions",
      "/dossiers-d-inscription",
      "/achats-boutique",
      "/articles-admin",
      "/depenses-recettes",
      "/coachings",
      "/heures-enseignement",
      "/inventaire",
      "/chat-page-admin",
      "/message-admin",
      "/parametres",
    ],
  };

  useEffect(() => {
    const newPathList = paths[role]
      ? paths[role].map((path) => path.toLowerCase().replace(/\/$/, ""))
      : [];
    setCurrentPaths(newPathList);
    const newPathIndex = newPathList.indexOf(
      location.pathname.toLowerCase().replace(/\/$/, "")
    );
    setCurrentIndex(newPathIndex);
  }, [location.pathname, role]);

  const hasPrevious = currentIndex > 0;
  const hasNext = currentIndex < currentPaths.length - 1;

  return (
    <div className="flex items-center justify-between p-4 dark:bg-slate-800 bg-slate-200 mb-4 font-montserrat">
      {hasPrevious ? (
        <button
          onClick={() => navigate(currentPaths[currentIndex - 1])}
          className="text-primary hover:text-opacity-80"
        >
          <FaArrowLeft size={24} />
        </button>
      ) : (
        <div className="w-6"></div>
      )}
      <div className="flex-grow text-center text-gray-800 dark:text-white">
        {currentIndex + 1} / {currentPaths.length}
      </div>
      {hasNext ? (
        <button
          onClick={() => navigate(currentPaths[currentIndex + 1])}
          className="text-primary hover:text-opacity-80"
        >
          <FaArrowRight size={24} />
        </button>
      ) : (
        <div className="w-6"></div>
      )}
    </div>
  );
};

export default DashboardNavigation;
