import React, { useState, useEffect } from "react";
import {
  getAllTeamMembers,
  addTeamMember,
  updateTeamMember,
  deleteTeamMember,
  updateTeamOrder,
  updateTeamMemberPhoto // Importer la fonction d'upload de photo
} from "../../../services/teamServices";
import Button from "../../../components/Button/Button";
import ButtonEdit from "../../../components/Button/ButtonEdit";
import ButtonDelete from "../../../components/Button/ButtonDelete";
import { FaPlus, FaSave, FaTimes } from "react-icons/fa";
import { TbTriangleFilled, TbTriangleInvertedFilled } from "react-icons/tb";
import { ToastContainer, toast } from "react-toastify";
import CreateTeamMemberModal from "./CreateTeamMemberModal";
import EditTeamMemberModal from "./EditTeamMemberModal";
import { useUser } from "../../Home/UserContext";
import { getRoleFromRoleId } from "../../../utils/roleHelper"; // Importer la fonction pour obtenir le rôle

const TeamManagement = () => {
  const [team, setTeam] = useState([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const { user } = useUser(); // Récupérer l'utilisateur
  const userRole = getRoleFromRoleId(user?.role_id); // Définir le rôle

  useEffect(() => {
    fetchTeamMembers();
  }, []);

  const fetchTeamMembers = async () => {
    try {
      const data = await getAllTeamMembers();
      setTeam(data);
    } catch (error) {
      console.error("Erreur lors de la récupération des membres de l'équipe", error);
    }
  };

  const handleDeleteTeamMember = async (member) => {
    if (window.confirm(`Êtes-vous sûr de vouloir supprimer ${member.nom} ${member.prenom} ?`)) {
      try {
        await deleteTeamMember(member.id);
        toast.success("Membre supprimé avec succès");
        fetchTeamMembers();
      } catch (error) {
        toast.error("Erreur lors de la suppression d’un membre");
        console.error("Erreur lors de la suppression d’un membre", error);
      }
    }
  };

  const openEditModal = (member) => {
    setSelectedMember(member);
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setSelectedMember(null);
    setIsEditModalOpen(false);
  };

  const openUploadModal = (member) => {
    setSelectedMember(member);
    setIsUploadModalOpen(true);
  };

  const closeUploadModal = () => {
    setSelectedMember(null);
    setSelectedFile(null);
    setIsUploadModalOpen(false);
  };

  const moveTeamMemberUp = async (index) => {
    if (index === 0) return;
    const newTeam = [...team];
    const temp = newTeam[index - 1];
    newTeam[index - 1] = newTeam[index];
    newTeam[index] = temp;
    newTeam[index - 1].position--;
    newTeam[index].position++;
    setTeam(newTeam);
    await updateTeamOrder(newTeam);
    fetchTeamMembers();
  };

  const moveTeamMemberDown = async (index) => {
    if (index === team.length - 1) return;
    const newTeam = [...team];
    const temp = newTeam[index + 1];
    newTeam[index + 1] = newTeam[index];
    newTeam[index] = temp;
    newTeam[index + 1].position++;
    newTeam[index].position--;
    setTeam(newTeam);
    await updateTeamOrder(newTeam);
    fetchTeamMembers();
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleUploadSubmit = async (e) => {
    e.preventDefault();
    if (!selectedFile) {
      toast.error("Veuillez sélectionner une image.");
      return;
    }

    setIsUploading(true);

    try {
      const token = localStorage.getItem("token");
      const imageUrl = await updateTeamMemberPhoto(selectedMember.id, selectedFile, token);
      setTeam((prevTeam) =>
        prevTeam.map((member) =>
          member.id === selectedMember.id ? { ...member, photoURL: imageUrl } : member
        )
      );
      toast.success("Photo mise à jour avec succès!");
      closeUploadModal(); // Fermer la modale après le succès
    } catch (error) {
      console.error("Erreur lors de l'upload de l'image :", error);
      toast.error("Erreur lors de l'upload de l'image.");
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div className="bg-white dark:bg-slate-300 shadow-md rounded-lg p-2 max-w-4xl w-full">
      <div className="flex justify-center mb-4">
        <Button
          text="Ajouter un membre"
          icon={FaPlus}
          onClick={() => userRole !== "visiteur" && setIsCreateModalOpen(true)}
          className={`mb-3 gap-2 ${userRole === "visiteur" ? "cursor-not-allowed opacity-50" : ""}`}
          hoverLabel="Ajouter un nouveau membre"
          disabled={userRole === "visiteur"} // Désactive le bouton pour les visiteurs
        />
      </div>
      {isCreateModalOpen && (
        <CreateTeamMemberModal
          isActive={isCreateModalOpen}
          onClose={() => setIsCreateModalOpen(false)}
          refreshTeam={fetchTeamMembers}
        />
      )}
      {isEditModalOpen && (
        <EditTeamMemberModal
          isActive={isEditModalOpen}
          onClose={closeEditModal}
          refreshTeam={fetchTeamMembers}
          memberData={selectedMember}
        />
      )}
      {isUploadModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-slate-200 dark:bg-slate-400 p-6 rounded-lg shadow-xl max-w-lg w-full">
            <h2 className="text-xl font-bold uppercase p-3 mb-4 text-center text-white bg-primary rounded-md shadow">
              Changer la photo de {selectedMember.nom} {selectedMember.prenom}
            </h2>
            <form
              onSubmit={handleUploadSubmit}
              className="flex flex-col items-center space-y-4 w-full"
            >
              <input
                type="file"
                onChange={handleFileChange}
                accept="image/*"
                className="my-2"
              />
              <div className="flex flex-auto gap-4">
                <Button
                  text="Sauvegarder"
                  type="submit"
                  icon={FaSave}
                  className="gap-2"
                />
                <Button
                  text="Fermer"
                  onClick={closeUploadModal}
                  icon={FaTimes}
                  className="gap-2"
                />
              </div>
            </form>
          </div>
        </div>
      )}
      <div className="overflow-x-auto">
        <h2 className="text-2xl font-bold mb-4 text-left dark:text-black">
          Gestion des Membres de l'Équipe
        </h2>
        <div className="overflow-x-auto">
          <table className="table-auto bg-white dark:bg-slate-300 font-montserrat max-w-[800px] mx-auto border-collapse border-2 border-slate-300">
            <thead className="bg-gray-800 text-white">
              <tr className="border-collapse border-2 border-slate-300">
                <th className="px-3 py-2 border-b-2 border-gray-200 text-center text-xs font-semibold text-gray-200 uppercase tracking-wider">
                  Photo
                </th>
                <th className="px-3 py-2 border-b-2 border-gray-200 text-center text-xs font-semibold text-gray-200 uppercase tracking-wider">
                  Nom
                </th>
                <th className="px-3 py-2 border-b-2 border-gray-200 text-center text-xs font-semibold text-gray-200 uppercase tracking-wider">
                  Prénom
                </th>
                <th className="px-3 py-2 border-b-2 border-gray-200 text-center text-xs text-gray-200 uppercase tracking-wider">
                  Fonction
                </th>
                <th className="px-3 py-2 border-b-2 border-gray-200 text-center text-xs text-gray-200 uppercase tracking-wider">
                  Catégorie
                </th>
                <th className="px-3 py-2 border-b-2 border-gray-200 text-center text-xs text-gray-200 uppercase tracking-wider">
                  Position
                </th>
                <th className="px-3 py-2 border-b-2 border-gray-200 text-center text-xs text-gray-200 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {team.map((member, index) => (
                <tr key={member.id}>
                  <td className="px-3 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    <div className="flex flex-col items-center">
                      <img
                        src={member.photoURL}
                        alt={`${member.nom} ${member.prenom}`}
                        className="w-16 h-16 rounded-full cursor-pointer"
                        onClick={() => openUploadModal(member)}
                      />
                      <span className="text-xs text-gray-500 mt-1 cursor-pointer" onClick={() => openUploadModal(member)}>
                        Changer de photo
                      </span>
                    </div>
                  </td>
                  <td className="px-3 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    {member.nom}
                  </td>
                  <td className="px-3 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    {member.prenom}
                  </td>
                  <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-900">
                    {member.fonction}
                  </td>
                  <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-900">
                    {member.categorie}
                  </td>
                  <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-900 flex items-center">
                    {member.position}
                    <div className="flex flex-col ml-2">
                      <button onClick={() => moveTeamMemberUp(index)} className="text-blue-400 hover:text-blue-900">
                        <TbTriangleFilled />
                      </button>
                      <button onClick={() => moveTeamMemberDown(index)} className="text-blue-400 hover:text-blue-900">
                        <TbTriangleInvertedFilled />
                      </button>
                    </div>
                  </td>
                  <td className="px-3 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <div className="flex">
                      <ButtonEdit
                        onClick={() => userRole !== "visiteur" && openEditModal(member)}
                        className={`mr-2 ${userRole === "visiteur" ? "cursor-not-allowed opacity-50" : ""}`}
                        hoverLabel={userRole === "visiteur" ? "Édition désactivée pour les visiteurs" : "Éditer"}
                        disabled={userRole === "visiteur"}
                      />
                      <ButtonDelete
                        onClick={() => userRole !== "visiteur" && handleDeleteTeamMember(member)}
                        className={`ml-2 ${userRole === "visiteur" ? "cursor-not-allowed opacity-50" : ""}`}
                        hoverLabel={userRole === "visiteur" ? "Suppression désactivée pour les visiteurs" : "Supprimer"}
                        disabled={userRole === "visiteur"}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default TeamManagement;
